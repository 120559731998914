.mainTaskboard {
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-top: 50px;
  background-color: #fff;
}

.taskColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 10px 0 10px;
}

.taskCard {
  width: 90%;
  margin: 20px;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
  transition-duration: .1s;
  min-height: 130px;
  height: auto;
  cursor: all-scroll;
  border: solid 1px rgba(0, 0, 0, 0.05);
}

.taskCard:hover {
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.15);
  background-color: #E2E5FF;
  transform: translateY(-3px);
}

.taskTitle {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.taskDate {
  font-size: 13px;
  margin-bottom: 10px;
}

.taskDescription {
  font-size: 15px;
  margin-bottom: 5px;
}

.taskPriority {
  font-size: 15px;
}

.taskCardHere {
  width: 90%;
  margin: 20px;
  padding: 0px 15px 15px 15px;
  border-radius: 8px;
  transition-duration: .1s;
  min-height: 130px;
  height: auto;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;

}

.taskCardHere:hover {
  background-color: rgba(0, 0, 0, 0.3);
}