.mainChain {
  min-height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-top: 50px;
}

.menuLeft {
  height: 100%;
  min-width: 180px;
  width: 180px;
  position: fixed;
  left: 0;
}

.itemContent {
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}


.tableContainer {
  width: 100%;
  height: 100%;
  margin-left: 180px;
}

.tableContainerUpcoming {
  width: 100%;
  height: 100%;
}

.rowList {
  min-width: 100px;
  background-color: 'red';
}

.meetingContainer {
  width: '100%';
  height: 50;
  display: flex;
}

.meetingCheckbox {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.meetingCheckboxHeader {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 2px rgba(0, 0, 0, 0.3);
}

.meetingItem {
  flex: 1;
  width: '100%';
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  transition-duration: .05s;
  cursor: pointer;
}

.meetingItem:hover {
  background-color: #E2E5FF;
}


.meetingItemHeader {
  flex: 1;
  width: '100%';
  height: 50;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: solid 2px rgba(0, 0, 0, 0.3);
}

.nameItem {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nameItemTrashHeader {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.nameItemTrash {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.iconItem {
  margin-right: 13px;
}

.statusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.buttonOpenContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 10px 50px;
}