.meetingCard {
  flex: 1;
  margin: 0 20px;
  padding: 0 20px 15px 20px;
  border-radius: 6px;
  transition-duration: .1s;
  max-width: 27%;
  min-height: 220px;
}

.title {
  margin-bottom: 0px;
}

.description {
  margin: 10px 0;
}

.boxMeeting {
  margin-bottom: 20px;
}

.inputMeeting {
  font-weight: 500;
  font-size: 14;
  line-height: 2px;
  ;
}

.titleMeeting {
  font-weight: '500';
  font-size: 15;
  color: #666;
}

.boxGroup {
  margin-top: 20px;
}

.boxDouble {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.datePicker {
  display: flex;
  flex-direction: row;
  width: 20%;

}

.smallBox2 {
  margin: 0 20px 0 20px;
  width: 20%;
}


.newMeeting {
  margin-left: 50px;
}

.informationBox {
  height: 350px;
  margin-top: 10px;
}