.mainLogin {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.headerBar {
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px 0 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100;
}

.dashboardContainer {
  height: 100%;
  width: 100%;
  display: block;
}

.main {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-top: 50px;
}

.list {
  border-bottom: solid 1px;
}

.containerLW {
  flex: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.containerST {
  flex: 1;
  padding: 15px 15px 5px 15px;
  display: flex;
}

.containerSTL {
  flex: 1;
  padding: 0px 30px;
  box-shadow: 3px 3px 3px rgba(100, 100, 100, 0.08);
}

.containerSB {
  flex: 1;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.containerSBL {
  width: 48%;
  min-height: 30px;
  padding: 0px 30px;
  padding-bottom: 0px;
  box-shadow: 3px 3px 3px rgba(100, 100, 100, 0.08);
}

.containerSBL2 {
  display: none;
  width: 31%;
  min-height: 30px;
  padding: 0px 30px;
  padding-bottom: 0px;
  box-shadow: 3px 3px 3px rgba(100, 100, 100, 0.08);
}


.containerLH {
  flex: 1;
  padding: 30px;
  display: flex;
  height: 100%;
}

.containerSLL {
  flex: 1;
  display: block;
  min-height: 700px;
  padding: 5px 30px;
  min-width: 240px;
}

.rowMeeting {
  height: calc(100% - 100px);
  padding-bottom: 10px;
  display: flex;
}

.viewAll {
  display: block;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 2px;
}

.ui-table__cell {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.hv {
  font-size: 0.875rem;
}

.fq {
  font-size: 0.875rem;
}

.listItem:hover {
  cursor: pointer;
  background-color: #fff;
}

.loaderMeeting {
  margin-left: 50%;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 25%;
}

.timePicker {
  flex: 1;
  background-color: #F0F0F0;
  border: none;
  border-radius: 5px;
}

.timePickerError {
  flex: 1;
  background-color: #F0F0F0;
  border: solid 1px #C4314B;
  border-radius: 5px;
}


.timePickerError:focus {
  border-bottom: solid 2px;
  border-bottom-color: #5B5FC7;
  outline: none;
}

.timePicker:focus {
  border-bottom: solid 2px;
  border-bottom-color: #5B5FC7;
  outline: none;
}

.smallBoxTime {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 25px;
}

@media screen and (max-width: 1400px) {
  .containerLH {
    display: none;
  }

  .containerSBL {
    width: 31%;
  }

  .containerSBL2 {
    display: block;
  }
}

.listItemChain {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.listItemChain:hover {
  cursor: pointer;
  background-color: #eee;
}